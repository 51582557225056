import { InitialPaginationParams } from '../../api/common/common.types';
import { Column } from '../table/table-head/CustomTableHead.types';

export const initialPaginationState: InitialPaginationParams = {
  defaultCurrentPage: 1,
  defaultOrderBy: 'id',
  defaultOrder: 'asc',
  defaultRowsPerPage: 25,
};

export const TABLE_COLUMNS_CONFIG: Column[] = [
  {
    id: 'title',
    label: 'common.title',
    width: '20%',
  },
  {
    id: 'status',
    label: 'common.status',
    width: '20%',
  },
  {
    id: 'actions',
    label: 'masterClass.actions',
    width: '10%',
    isNotSortable: true,
  },
];
