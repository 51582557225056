export const languageLabel = {
  no_data: 'No labels found',
  title: 'Language labels',
  add: 'CREATE A LABEL',
  confirm_delete_title: 'Confirm deletion',
  confirm_delete_message: 'Are you sure you want to delete this label ?',
  delete_success_message: 'Label was successfully deleted',
  view_language_title: 'View label details',
  create_success_message: 'Label has been successfully created',
  update_language_title: 'Edit a label',
  add_language_title: 'Create a label',
  update_success_message: 'Label has been successfully changed',
  form_fields: {
    title: {
      label: 'Language title*',
      placeholder: 'Language title',
      required_error_message: 'Language title field is required',
      max_length_error_message: 'Language title cannot be longer than 60 characters',
    },
    labelName: {
      label: 'Label name*',
      placeholder: 'Label name',
      required_error_message: 'Label name field is required',
    },
    language: {
      label: 'Language*',
      placeholder: 'Language',
      required_error_message: 'All Languages are required',
    },
  },
};
