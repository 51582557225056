import ArtWeek from '../../../models/ArtWeek';

export interface IArtWeekResponse {
  total: number;
  next: number | null;
  previous: number | null;
  currentArtOfTheWeekCount: number | 0;
  arts: ArtWeek[];
}

export interface IArtWeekState {
  isLoading: boolean;
  arts: IArtWeekResponse | null;
  artweekusers: ArtWeek | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_ART_WEEK_SUCCESS = 'FETCH_ART_WEEK_SUCCESS',
  FETCH_ART_WEEK_FAILURE = 'FETCH_ART_WEEK_FAILURE',
  CREATE_ART_WEEK_SUCCESS = 'CREATE_ART_WEEK_SUCCESS',
}

export interface IArtWeekPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchArtWeekSuccessAction {
  type: typeof ActionTypes.FETCH_ART_WEEK_SUCCESS | typeof ActionTypes.CREATE_ART_WEEK_SUCCESS;
  payload: IArtWeekResponse | ArtWeek;
}

export interface IFetchArtWeekRejectedAction {
  type: typeof ActionTypes.FETCH_ART_WEEK_FAILURE;
  payload: string;
}

export type ArtWeekActionTypes = IArtWeekPendingAction | IFetchArtWeekSuccessAction | IFetchArtWeekRejectedAction;
