/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchArtistsSuccessAction, ArtistsActionTypes } from '../slice/ArtistsView-slice.types';
import { artistsActions, artistsReducer } from '../slice/Artistsview-slice';

export const artistsApi = {
  reducerPath: 'artsusers',
  reducer: artistsReducer,
  endpoints: {
    getArtistsUsers: (params: any, id: number) => async (dispatch: ThunkDispatch<RootState, undefined, ArtistsActionTypes>) => {
      dispatch(artistsActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.GET_PORTFOLIO_TRANSACTIONS}/${id}`, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(artistsActions.matchFulFilled(responseBody, 'fetch') as IFetchArtistsSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(artistsActions.matchRejected(error));
        });
    },
  },
};
