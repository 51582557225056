/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IFetchLanguageLabelSuccessAction, LanguageLabelActionTypes } from '../slice/LanguageLabel-slice.types';
import { languageLabelActions, languageLabelReducer } from '../slice/LanguageLabel-slice';

export const languageLabelApi = {
  reducerPath: 'languageUsers',
  reducer: languageLabelReducer,
  endpoints: {
    getLanguageLabels: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, LanguageLabelActionTypes>) => {
      dispatch(languageLabelActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.GET_LABELS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(languageLabelActions.matchFulFilled(responseBody, 'fetch') as IFetchLanguageLabelSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(languageLabelActions.matchRejected(error));
        });
    },
    updateLanguageLabel: (id: number, data: any) => async (dispatch: ThunkDispatch<RootState, undefined, LanguageLabelActionTypes>) => {
      dispatch(languageLabelActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Put, `${ENDPOINTS.GET_LABELS}/${id}`, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(languageLabelActions.matchFulFilled(responseBody, 'update') as IFetchLanguageLabelSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(languageLabelActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
    createLanguageLabel: (data: any) => async (dispatch: ThunkDispatch<RootState, undefined, LanguageLabelActionTypes>) => {
      dispatch(languageLabelActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Post, ENDPOINTS.GET_LABELS, data, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(languageLabelActions.matchFulFilled(responseBody, 'create') as IFetchLanguageLabelSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(languageLabelActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
    deleteLanguageLabel: (id: number) => async (dispatch: ThunkDispatch<RootState, undefined, LanguageLabelActionTypes>) => {
      dispatch(languageLabelActions.matchPending());
      return new Promise((resolve, reject) => {
        sendAsyncRequest(HttpMethod.Delete, `${ENDPOINTS.GET_LABELS}/${id}`, {}, true, {})
          .then((response: AxiosResponse) => {
            const responseBody = response.data;
            dispatch(languageLabelActions.matchFulFilled(responseBody, 'delete') as IFetchLanguageLabelSuccessAction);
            resolve(responseBody); // Resolve the promise when request succeeds
          })
          .catch((error) => {
            const responseError = error.response.data;
            console.log('error', responseError.message);
            dispatch(languageLabelActions.matchRejected(responseError));
            reject(responseError); // Reject the promise when request fails
          });
      });
    },
  },
};
