import React from 'react';

export const PortfolioPlanIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M21.6 17.6172V16.4345H20.328C20.2456 15.9851 20.0689 15.5583 19.8096 15.1822L20.7086 14.2829L19.872 13.4465L18.9727 14.3458C18.5967 14.0863 18.17 13.9094 17.7206 13.8267V12.5547H16.5382V13.8267C16.0887 13.9094 15.6619 14.0863 15.2858 14.3458L14.3868 13.4465L13.5504 14.2829L14.4497 15.1822C14.1901 15.5583 14.0132 15.9851 13.9306 16.4345H12.6586V17.6172H13.9306C14.0132 18.0666 14.1902 18.4933 14.4497 18.8693L13.5504 19.7686L14.3868 20.605L15.2858 19.7057C15.6619 19.9652 16.0887 20.1422 16.5382 20.2248V21.4968H17.7211V20.2248C18.1704 20.1422 18.5972 19.9652 18.9732 19.7057L19.872 20.605L20.7082 19.7686L19.8096 18.8696C20.0689 18.4934 20.2455 18.0666 20.328 17.6172H21.6ZM17.1295 18.6864C16.801 18.6864 16.4799 18.589 16.2068 18.4065C15.9337 18.224 15.7208 17.9646 15.5951 17.6612C15.4694 17.3577 15.4365 17.0237 15.5006 16.7016C15.5647 16.3794 15.7229 16.0835 15.9552 15.8512C16.1875 15.6189 16.4835 15.4608 16.8057 15.3967C17.1278 15.3327 17.4618 15.3656 17.7652 15.4913C18.0687 15.6171 18.3281 15.83 18.5105 16.1031C18.693 16.3763 18.7904 16.6974 18.7903 17.0259C18.7898 17.4662 18.6147 17.8883 18.3033 18.1996C17.9919 18.5109 17.5698 18.686 17.1295 18.6864Z"
        fill="white"
      />
      <path
        d="M18.4102 5.18262H3.26903C3.03855 5.18262 2.81751 5.27418 2.65453 5.43715C2.49155 5.60013 2.39999 5.82117 2.39999 6.05166V7.29606C3.27815 9.75894 5.63447 11.5069 8.37431 11.5069H9.01055C9.00911 11.4761 9.00839 11.4454 9.00839 11.4145C9.00559 11.1675 9.05183 10.9224 9.14441 10.6933C9.237 10.4643 9.3741 10.256 9.54777 10.0803C9.72145 9.90466 9.92824 9.76521 10.1562 9.67003C10.3841 9.57485 10.6287 9.52585 10.8757 9.52585C11.1227 9.52585 11.3673 9.57485 11.5952 9.67003C11.8232 9.76521 12.03 9.90466 12.2037 10.0803C12.3773 10.256 12.5144 10.4643 12.607 10.6933C12.6996 10.9224 12.7458 11.1675 12.743 11.4145C12.743 11.4454 12.743 11.4761 12.7409 11.5069H13.3049C16.0445 11.5069 18.4008 9.75894 19.2792 7.2963V6.05166C19.2792 5.93753 19.2567 5.82453 19.213 5.71909C19.1694 5.61365 19.1054 5.51785 19.0247 5.43715C18.944 5.35646 18.8482 5.29244 18.7427 5.24877C18.6373 5.2051 18.5243 5.18262 18.4102 5.18262Z"
        fill="white"
      />
      <path
        d="M13.8602 3.55415V4.70279H13.0202V3.55415C13.0202 3.49841 12.998 3.44497 12.9586 3.40555C12.9192 3.36614 12.8657 3.34397 12.81 3.34391H8.86943C8.81367 3.34397 8.76021 3.36613 8.72076 3.40554C8.6813 3.44495 8.65908 3.49838 8.65895 3.55415V4.70279H7.81895V3.55415C7.81934 3.27568 7.93014 3.00873 8.12707 2.81185C8.324 2.61497 8.59097 2.50422 8.86943 2.50391H12.81C13.0884 2.50422 13.3554 2.61498 13.5523 2.81187C13.7492 3.00875 13.8599 3.2757 13.8602 3.55415Z"
        fill="white"
      />
      <path
        d="M13.3049 12.0497H12.6329C12.5021 12.4105 12.2634 12.7223 11.9492 12.9427C11.635 13.163 11.2605 13.2813 10.8767 13.2813C10.4929 13.2813 10.1184 13.163 9.80417 12.9427C9.48994 12.7223 9.25122 12.4105 9.12048 12.0497H8.37648C5.83248 12.0497 3.59376 10.662 2.40192 8.58789V17.5699C2.40192 17.7684 2.48076 17.9587 2.62109 18.099C2.76143 18.2394 2.95177 18.3182 3.15024 18.3182H12.1793V15.9547H13.5528C13.6252 15.712 13.7224 15.4774 13.843 15.2546L12.8717 14.2831L14.3868 12.768L15.3581 13.7393C15.5809 13.6188 15.8155 13.5215 16.0582 13.4491V12.0756H18.2011V13.4491C18.4336 13.5188 18.6587 13.6112 18.8731 13.7251L19.2792 13.32V8.58813C18.0874 10.662 15.8484 12.0497 13.3049 12.0497Z"
        fill="white"
      />
    </svg>
  );
};

export default PortfolioPlanIcon;
