/* eslint-disable @typescript-eslint/no-explicit-any */
import { PortfolioTransactions } from '../../../models/PortfolioTransactions';
import {
  ActionTypes,
  IFetchPortfolioReportRejectedAction,
  IPortfolioReportSuccessAction,
  IPortfolioReportPendingAction,
  IPortfolioReportResponse,
  IPortfolioReportState,
  PortfolioReportActionTypes,
} from './PortfolioTransactions-slice.types';

export const initialState: IPortfolioReportState = {
  isLoading: false,
  error: null,
  data: null,
  portfolioTransactionUsers: null,
};

export const portfolioTransactionReportReducer = (state = initialState, action: PortfolioReportActionTypes): IPortfolioReportState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_PORTFOLIO_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        data: action.payload as IPortfolioReportResponse,
      };
    case ActionTypes.FETCH_PORTFOLIO_REPORT_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_PORTFOLIO_REPORT_SUCCESS:
      return {
        ...state,
        isLoading: false,
        portfolioTransactionUsers: action.payload as PortfolioTransactions,
      };
    default:
      return state;
  }
};

const matchPending = (): IPortfolioReportPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: IPortfolioReportResponse, action: 'fetch'): IPortfolioReportSuccessAction | PortfolioTransactions => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_PORTFOLIO_REPORT_SUCCESS : ActionTypes.CREATE_PORTFOLIO_REPORT_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchPortfolioReportRejectedAction => {
  return {
    type: ActionTypes.FETCH_PORTFOLIO_REPORT_FAILURE,
    payload: error,
  };
};

export const portfolioTransactionReportActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
