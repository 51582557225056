import Artists from '../../../models/Artist';

export interface IArtistsResponse {
  total: number;
  next: number | null;
  previous: number | null;
  transactions: Artists[];
}

export interface IArtistsUsersState {
  isLoading: boolean;
  transactions: IArtistsResponse | null;
  artists: Artists | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_ARTISTS_SUCCESS = 'FETCH_ARTISTS_SUCCESS',
  FETCH_ARTISTS_FAILURE = 'FETCH_ARTISTS_FAILURE',
  CREATE_ARTISTS_SUCCESS = 'CREATE_ARTISTS_SUCCESS',
}

export interface IArtistsPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchArtistsSuccessAction {
  type: typeof ActionTypes.FETCH_ARTISTS_SUCCESS | typeof ActionTypes.CREATE_ARTISTS_SUCCESS;
  payload: IArtistsResponse | Artists;
}

export interface IFetchArtistsRejectedAction {
  type: typeof ActionTypes.FETCH_ARTISTS_FAILURE;
  payload: string;
}

export type ArtistsActionTypes = IArtistsPendingAction | IFetchArtistsSuccessAction | IFetchArtistsRejectedAction;
