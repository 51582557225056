import React from 'react';
import { Button, TableRow, Stack, IconButton, Tooltip } from '@mui/material';
import { TableCellStyle } from './WelcomeVideo.styles';
import { WelcomeVideoRowProps } from './WelcomeVideo.types';
import EyeIcon from '../../svgComponents/EyeIcon';
import Iconify from '../../Iconify';
import { ReactComponent as EditIcon } from '../../../assets/icons/pencil-outline.svg';

export default function WelcomeVideoTableRow({ row, selected, columnsConfig, onShowDetails, onOPenEditDialog }: WelcomeVideoRowProps) {
  const { id, status } = row;
  return (
    <TableRow key={id} hover selected={selected} sx={{ backgroundColor: '#ffffff' }}>
      {columnsConfig.map((column) => (
        <TableCellStyle key={column.id} align={column.align || 'left'} width={column.width}>
          {column.id === 'status' ? (
            typeof status === 'number' && status === 1 ? (
              'Yes'
            ) : (
              'No'
            )
          ) : column.id !== 'actions' ? (
            String(row[column.id as keyof typeof row])
          ) : (
            <Stack direction="row" spacing={2}>
              <Tooltip title="View Details" arrow>
                <IconButton
                  sx={{
                    padding: '0px !important',
                  }}
                  onClick={() => onShowDetails(id, true)}
                >
                  <EyeIcon />
                </IconButton>
              </Tooltip>
              <Tooltip title="Edit" arrow>
                <IconButton onClick={() => onOPenEditDialog(id)}>
                  <Iconify icon={EditIcon} />
                </IconButton>
              </Tooltip>
            </Stack>
          )}
        </TableCellStyle>
      ))}
    </TableRow>
  );
}
