/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useState } from 'react';
import {
  Box,
  Container,
  Grid,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableRow,
  ThemeProvider,
  Tooltip,
  Typography,
} from '@mui/material';
import TablePagination from '@mui/material/TablePagination';
import TableFooter from '@mui/material/TableFooter';
import { tableTheme } from '../../../theme/table-theme';
import { HeadTable } from '../tableHead/HeadTable';
import { TablePaginationActions } from '../TablePaginationActions/TablePaginationActions';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../../store/rootStore';
import { useDispatch, useSelector } from 'react-redux';
import { PaginationActionTypes } from '../../../store/pagination/models';
import { getPageNumber, getPerPage } from '../../../store/pagination/actions';
import Spinner from '../../spinner';
import { NoDataFoundComponent } from '../../shared/NoDataFoundComponent/NoDataFoundComponent';
import { rowTheme } from '../../../theme/rowtheme';
import HeaderProperty from '../../../models/Table';
import { TableCellStyle } from '../../adsUsers/adsUsers-row/AdsUsersRow.styles';
import { RootStyle } from '../../../pages/systemusers/SystemUser.styles';
import TableLoadingData from '../TableLoadingData';
import TableNoData from '../TableNoData';
import { useTranslation } from 'react-i18next';
import EyeIcon from '../../svgComponents/EyeIcon';
import ViewArtistListing from '../../artists/viewArtists/ViewArtistsListing';
interface TableFactoryProps {
  loading: boolean;
  bodyData?: any | undefined;
  headItems: HeaderProperty[];
  isView?: boolean;
  onShowDetails?: (id?: number, name?: string, isShow?: boolean) => void;
}

/**
 *component returning all forms of tables except planning table
 *
 * @returns {ExoticComponent<() => JSX.Element>}
 */
export const TableUsers = (props: TableFactoryProps) => {
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, PaginationActionTypes>>();
  const paginationState = useSelector((state: RootState) => state.pagination);
  const { total, pageNumber, perPage } = paginationState;
  const { loading, bodyData, headItems, isView, onShowDetails } = props;
  const { t } = useTranslation();

  const handleChangePage = (event: React.MouseEvent<HTMLButtonElement> | null, newPage: number) => {
    dispatch(getPageNumber(newPage));
  };

  const handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
    dispatch(getPerPage(parseInt(event.target.value, 10)));
    dispatch(getPageNumber(0));
  };

  const isFullFilled = !loading && bodyData && bodyData?.length > 0;

  return (
    <>
      <RootStyle>
        <Container sx={{ minHeight: '66vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <HeadTable items={headItems} />
                <TableBody>
                  {isFullFilled && (
                    <>
                      {bodyData.map((row: any, index: number) => {
                        return (
                          <>
                            <TableRow style={{ backgroundColor: '#ffffff' }}>
                              {headItems.map((cell: any, headIndex: number) => {
                                return <TableCellStyle key={headIndex}>{row[cell.value]}</TableCellStyle>;
                              })}
                              {isView && onShowDetails && (
                                <TableCellStyle>
                                  <Grid item>
                                    <IconButton onClick={() => onShowDetails(row.id, row.fullname, true)}>
                                      <EyeIcon />
                                    </IconButton>
                                  </Grid>
                                </TableCellStyle>
                              )}
                            </TableRow>
                          </>
                        );
                      })}
                    </>
                  )}
                  <TableNoData isNotFound={!loading && bodyData?.length === 0} title={t('adds.userreports_no_data')} />
                  <TableLoadingData isLoading={loading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={total || 0}
                rowsPerPage={perPage}
                page={pageNumber}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiTablePagination-selectLabel': {
                    display: 'inline',
                  },
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
};
