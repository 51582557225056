import * as yup from 'yup';

export const CreateWelcoemVideoSchema = yup.object().shape({
  title: yup
    .string()
    .trim()
    .max(50, 'masterClass.form_fields.title.max_length_error_message')
    .required('masterClass.form_fields.title.required_error_message'),
  video: yup.string().required('masterClass.form_fields.video.required_error_message'),
});
