import React from 'react';

export const WelcomeVideoIcon = () => {
  return (
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M13.553 6H4.23462C3.00558 6 2 7.00558 2 8.23462V15.7653C2 16.9943 3.00558 17.9999 4.23462 17.9999H13.553C14.782 17.9999 15.7876 16.9943 15.7876 15.7653V8.23462C15.7876 6.98323 14.782 6 13.553 6Z"
        fill="white"
      />
      <path
        d="M20.7709 7.22886C20.6368 7.25121 20.5027 7.31825 20.391 7.38529L16.905 9.39645V14.5808L20.4133 16.5919C21.0614 16.9718 21.8658 16.7484 22.2457 16.1003C22.3575 15.8992 22.4245 15.6757 22.4245 15.4299V8.52495C22.4245 7.69814 21.6424 7.02775 20.7709 7.22886Z"
        fill="white"
      />
    </svg>
  );
};

export default WelcomeVideoIcon;
