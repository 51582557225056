import { PortfolioTransactions } from '../../../models/PortfolioTransactions';

export interface IPortfolioReportResponse {
  total: number;
  next: number | null;
  previous: number | null;
  data: {
    transactionData: PortfolioTransactions[];
    totalPrice: number;
  };
}

export interface IPortfolioReportState {
  isLoading: boolean;
  data: IPortfolioReportResponse | null;
  portfolioTransactionUsers: PortfolioTransactions | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_PORTFOLIO_REPORT_SUCCESS = 'FETCH_PORTFOLIO_REPORT_SUCCESS',
  FETCH_PORTFOLIO_REPORT_FAILURE = 'FETCH_PORTFOLIO_REPORT_FAILURE',
  CREATE_PORTFOLIO_REPORT_SUCCESS = 'CREATE_PORTFOLIO_REPORT_SUCCESS',
}

export interface IPortfolioReportPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IPortfolioReportSuccessAction {
  type: typeof ActionTypes.FETCH_PORTFOLIO_REPORT_SUCCESS | typeof ActionTypes.CREATE_PORTFOLIO_REPORT_SUCCESS;
  payload: IPortfolioReportResponse | PortfolioTransactions;
}

export interface IFetchPortfolioReportRejectedAction {
  type: typeof ActionTypes.FETCH_PORTFOLIO_REPORT_FAILURE;
  payload: string;
}

export type PortfolioReportActionTypes = IPortfolioReportPendingAction | IPortfolioReportSuccessAction | IFetchPortfolioReportRejectedAction;
