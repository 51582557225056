/* eslint-disable @typescript-eslint/no-explicit-any */
import Artists from '../../../models/Artist';
import {
  ActionTypes,
  IFetchArtistsRejectedAction,
  IFetchArtistsSuccessAction,
  IArtistsPendingAction,
  IArtistsResponse,
  IArtistsUsersState,
  ArtistsActionTypes,
} from './ArtistsView-slice.types';

export const initialState: IArtistsUsersState = {
  isLoading: false,
  error: null,
  transactions: null,
  artists: null,
};

export const artistsReducer = (state = initialState, action: ArtistsActionTypes): IArtistsUsersState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_ARTISTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        transactions: action.payload as IArtistsResponse,
      };
    case ActionTypes.FETCH_ARTISTS_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_ARTISTS_SUCCESS:
      return {
        ...state,
        isLoading: false,
        artists: action.payload as Artists,
      };
    default:
      return state;
  }
};

const matchPending = (): IArtistsPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: IArtistsResponse, action: 'fetch'): IFetchArtistsSuccessAction | Artists => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_ARTISTS_SUCCESS : ActionTypes.CREATE_ARTISTS_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchArtistsRejectedAction => {
  return {
    type: ActionTypes.FETCH_ARTISTS_FAILURE,
    payload: error,
  };
};

export const artistsActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
