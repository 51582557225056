/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import { yupResolver } from '@hookform/resolvers/yup';
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Grid, Stack, Button, CircularProgress, Container, Backdrop, FormControl, InputLabel, Select, MenuItem } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import CustomLoadingButton from '../../button/CustomLoadingButton';
import FormProvider from '../../hook-form/FormProvider';
import RHFTextField from '../../hook-form/RHFTextField';
import useSnackBar from '../../../hooks/useSnackBar';
import { RootState } from '../../../store/rootStore';
import { WelcomeVideoFormProps } from './WelcomeVideo-form.types';
import { CreateWelcoemVideoSchema } from './create-welcome.schema';
import AddPhotoIcon from '../../svgComponents/AddPhotoIcon';
import AdminVideoControl from '../../adds/menubar/pending/form/VideoControl';
import { colors } from '../../../theme/sharedTheme';
import { Welcome } from '../../../models/Welcome';
import { WelcomeVideoActionTypes } from '../../../store/welcomeVideo/slice/WelcomeVideo-slice.types';
import { welcomeVideoApi } from '../../../store/welcomeVideo/api/WelcomeVideo.api';

const { updateWelcomeVideo } = welcomeVideoApi.endpoints;

// Example tag options

export default function WelcomeVideoForm({ currentWelcomeVideo, isUpdate, onClose, isEyeIconClicked }: WelcomeVideoFormProps) {
  const { t } = useTranslation();
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, WelcomeVideoActionTypes>>();
  const [loading, setLoading] = useState<boolean>(false);
  const [videoURL, setVideoURL] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<string | undefined>(undefined);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);
  const [postURL, setPostURL] = useState<string | undefined>(undefined);
  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [postError, setPostError] = useState<string | undefined>(undefined);

  const defaultValues = useMemo<Welcome>(
    () => ({
      title: currentWelcomeVideo?.title || '',
      video: currentWelcomeVideo?.video || '',
      status: currentWelcomeVideo?.status !== undefined ? currentWelcomeVideo?.status : 1, // Ensure the correct value is set
    }),
    [currentWelcomeVideo],
  );

  useEffect(() => {
    if (currentWelcomeVideo) {
      const posturl = currentWelcomeVideo?.video || '';
      const postExtension = posturl.split('.').pop()?.toLowerCase() || '';
      setPostURL(posturl);
      setPostType(postExtension);
    }
  }, [currentWelcomeVideo]);

  const methods = useForm<Welcome>({
    resolver: yupResolver(CreateWelcoemVideoSchema),
    defaultValues,
    mode: 'all',
  });

  const { alertSuccess, alertError } = useSnackBar();

  const {
    handleSubmit,
    watch,
    setValue,
    reset,
    trigger,
    clearErrors,
    formState: { errors, isSubmitting },
  } = methods;

  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentWelcomeVideo]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentWelcomeVideo?.title || '',
      video: currentWelcomeVideo?.video || '',
      status: currentWelcomeVideo?.status !== undefined ? currentWelcomeVideo?.status : 1, // Ensure the correct value is set
    };
    methods.reset(updatedDefaultValues);
  }, [currentWelcomeVideo, isUpdate]);

  const onSubmit = async () => {
    if (isUpdate) {
      update();
    }
  };
  console.log('sdsdsfds', values.status);
  const update = async () => {
    try {
      setLoading(true); // Start loading
      const isValid = await trigger();
      if (isValid && currentWelcomeVideo?.id) {
        const formData = new FormData();
        formData.append('title', values.title || '');
        formData.append('video', values.video || '');
        formData.append('status', String(values.status));

        await dispatch(updateWelcomeVideo(currentWelcomeVideo?.id, formData));
        reset(defaultValues);
        dispatch(alertSuccess(t('masterClass.welcome_update_success_message')));
        onClose();
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
      console.error(err);
    } finally {
      setLoading(false); // End loading
    }
  };

  const openVideoPopup = () => {
    setVideoURL(postURL);
    setVideoType(postType);
    setVideoPopupOpen(true);
  };

  const handleLogoUpload = (e: any) => {
    const logoFile = e.target.files[0];
    const imageUrl = URL.createObjectURL(logoFile);
    const fileType = logoFile?.type.split('/')[1];
    if (logoFile) {
      if (logoFile.type.includes('video')) {
        setPostError(undefined);
        setValue('video', logoFile);
        setPostType(fileType);
        setPostURL(imageUrl || undefined);
      } else {
        setPostError('Please choose a video file.');
        setPostURL(undefined);
        setPostType(undefined);
      }
    }
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  const isShowOption = [
    { label: 'Yes', value: 1 },
    { label: 'No', value: 0 },
  ];

  return (
    <>
      <Backdrop
        sx={{
          color: colors.yellow,
          display: 'flex',
          flexDirection: 'column',
          gap: 2,
          zIndex: (theme) => theme.zIndex.drawer + 1, // Ensure this value is high enough
        }}
        open={loading}
      >
        <CircularProgress color="inherit" />
        <h2>{t('common.loading')}</h2>
      </Backdrop>
      <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
        <Grid className={`artgapi-modal`} container spacing={3}>
          <Grid className="artgapi-plan-form" item xs={12} md={12}>
            <Box sx={{ p: 3 }}>
              <Box sx={{ mb: 3 }}>
                <Box
                  sx={{
                    display: 'grid',
                    columnGap: 2,
                    rowGap: 3,
                    gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(2, 1fr)' },
                  }}
                >
                  <RHFTextField
                    name={'title'}
                    placeholder={t('masterClass.form_fields.title.placeholder')}
                    label={t('masterClass.form_fields.title.label')}
                    disabled={isEyeIconClicked}
                  />
                  <FormControl fullWidth>
                    <InputLabel
                      id="status-label"
                      sx={{
                        transform: 'translate(0px, 0px) scale(0.95)',
                        position: 'relative !important',
                        maxWidth: '0',
                        minWidth: '100%',
                        color: 'rgb(171, 171, 171)',
                        marginBottom: '0.5rem',

                        '& .css-to7sdl-MuiFormControl-root': {
                          marginTop: '4px !important',
                        },
                      }}
                    >
                      {t('masterClass.form_fields.show_video.placeholder')}
                    </InputLabel>
                    <Controller
                      name="status"
                      control={methods.control}
                      render={({ field, fieldState: { error } }) => (
                        <>
                          <Select
                            {...field}
                            sx={{
                              width: '100%',
                              height: '48px',
                              borderRadius: '0',
                              '& .MuiSelect-select': {
                                width: '100%',
                                height: '48px !important',
                                alignItems: 'center',
                                justifyContent: 'center',
                                padding: '12.5px 14px',
                                paddingRight: '110px !important',
                              },
                              '& .MuiOutlinedInput-root': {
                                marginTop: '-8px !important',
                              },
                              '& .MuiOutlinedInput-input': {
                                border: isEyeIconClicked ? '1px solid #e7e7e7' : '1px solid #0d0d0d',
                                borderColor: error && '#d32f2f',
                                borderRadius: '0',
                              },
                              '& .MuiSelect-select:hover': {
                                borderColor: '#141415 !important',
                              },
                            }}
                            labelId="status-label"
                            id="id"
                            value={field.value}
                            onChange={(e) => field.onChange(e.target.value)}
                            disabled={isEyeIconClicked}
                          >
                            {/* Map over the list of countries */}
                            {isShowOption?.map((item) => (
                              <MenuItem key={item.value} value={item.value}>
                                {item.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {/* Display error message if there's an error for countryId */}
                        </>
                      )}
                    />
                  </FormControl>
                </Box>
              </Box>

              <Box sx={{ mb: 3 }}>
                <Grid>
                  <div>
                    <label className="MuiInputLabel-root MuiInputLabel-animated MuiFormLabel-root MuiFormLabel-colorPrimary css-r440dj-MuiFormLabel-root-MuiInputLabel-root">
                      {t('masterClass.form_fields.video.label')}
                    </label>
                    <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                      {postURL ? (
                        <div
                          style={{
                            width: '100px',
                            height: '100px',
                            display: 'flex',
                            alignItems: 'center',
                            justifyContent: 'center',
                            position: 'relative',
                          }}
                        >
                          <video
                            style={{ cursor: 'pointer', width: '100%', objectFit: 'cover', height: '100%' }}
                            src={postURL}
                            onClick={openVideoPopup}
                          ></video>
                          <span
                            style={{
                              backgroundColor: 'white',
                              display: 'flex',
                              alignItems: 'center',
                              borderRadius: '9999px',
                              position: 'absolute',
                              width: '1.5rem',
                              height: '1.5rem',
                              cursor: 'pointer',
                              justifyContent: 'center',
                            }}
                          >
                            <PlayCircleOutlineIcon className="text-base" onClick={openVideoPopup} />
                          </span>
                        </div>
                      ) : (
                        <div style={{ display: 'flex', alignItems: 'center', gap: '10px', marginBottom: '0px' }}>
                          <label
                            htmlFor="logos"
                            style={{
                              width: '44px',
                              height: '44px',
                              minWidth: '44px',
                              backgroundColor: '#f0f0f0',
                              border: '1px dashed #ccc',
                              display: 'flex',
                              alignItems: 'center',
                              justifyContent: 'center',
                            }}
                          >
                            <AddPhotoIcon />
                          </label>
                        </div>
                      )}
                      {!isEyeIconClicked && (
                        <Button
                          className="artgapi-upload-btn"
                          style={{
                            textTransform: 'capitalize',
                            boxShadow: 'inset 0px -1px 0px #E7E7E7',
                            marginLeft: '15px',
                            backgroundColor: '#ffcc33',
                          }}
                          size="small"
                          variant="contained"
                          component="label"
                          disabled={isEyeIconClicked}
                        >
                          {t('articles.upload')}
                          <input hidden type="file" id="video-url" onChange={handleLogoUpload} accept="video/*" />
                        </Button>
                      )}
                    </div>
                    {errors?.video && !postError && (
                      <p style={{ color: '#d32f2f', marginTop: '0.5rem', fontSize: '0.75rem' }}>
                        {t('masterClass.form_fields.video.required_error_message')}
                      </p>
                    )}
                    {postError && <p style={{ color: '#d32f2f', marginTop: '0.5rem', fontSize: '0.75rem' }}>{postError}</p>}
                  </div>
                </Grid>
              </Box>

              {/* Submit Button */}
              {!isEyeIconClicked && (
                <Stack alignItems="flex-end" sx={{ mt: 3 }}>
                  <CustomLoadingButton
                    loading={loading || isSubmitting}
                    label={!isUpdate ? t('interests.create_button_label') : t('interests.update_button_label')}
                    onClick={onSubmit}
                  />
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
      </FormProvider>

      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoURL || ''}
          selectedVideo={videoType || ''}
        />
      )}
    </>
  );
}
