import { articles } from './articles';
import { common } from './common';
import { menu } from './menu';
import { opportunities } from './opportunities';
import { profile } from './profile';
import { university } from './university';
import { validations } from './validation';
import { systemuser } from './systemuser';
import { adds } from './adds';
import { interests } from './interests';
import { masterClass } from './masterClass';
import { subscriptionPlan } from './subscriptionPlan';
import { advertisements } from './advertisments';
import { portfolioPlans } from './portfolioPlan';
import { languageLabel } from './langaugeLabel';

const en = {
  common,
  articles,
  opportunities,
  menu,
  validations,
  university,
  profile,
  systemuser,
  adds,
  interests,
  masterClass,
  subscriptionPlan,
  advertisements,
  portfolioPlans,
  languageLabel,
};
export default en;
