/* eslint-disable @typescript-eslint/no-explicit-any */
import ArtWeek from '../../../models/ArtWeek';
import {
  ActionTypes,
  IFetchArtWeekRejectedAction,
  IFetchArtWeekSuccessAction,
  IArtWeekPendingAction,
  IArtWeekResponse,
  IArtWeekState,
  ArtWeekActionTypes,
} from './ArtWeek-slice.types';

export const initialState: IArtWeekState = {
  isLoading: false,
  error: null,
  arts: null,
  artweekusers: null,
};

export const artWeekReducer = (state = initialState, action: ArtWeekActionTypes): IArtWeekState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_ART_WEEK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        arts: action.payload as IArtWeekResponse,
      };
    case ActionTypes.FETCH_ART_WEEK_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_ART_WEEK_SUCCESS:
      return {
        ...state,
        isLoading: false,
        artweekusers: action.payload as ArtWeek,
      };
    default:
      return state;
  }
};

const matchPending = (): IArtWeekPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (data: IArtWeekResponse, action: 'fetch' | 'create' | 'delete'): IFetchArtWeekSuccessAction | ArtWeek => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_ART_WEEK_SUCCESS : ActionTypes.CREATE_ART_WEEK_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchArtWeekRejectedAction => {
  return {
    type: ActionTypes.FETCH_ART_WEEK_FAILURE,
    payload: error,
  };
};

export const artWeekActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
