/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import React, { useState, useEffect } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { ThunkDispatch } from 'redux-thunk';
import { useDispatch, useSelector } from 'react-redux';
import { useDebounce } from '@uidotdev/usehooks';
import { AxiosResponse } from 'axios';
import { LanguageLabel } from '../../models/LanguageLabel';
import { ILanguageLabelResponse, ILanguageLabelState, LanguageLabelActionTypes } from '../../store/languageLabel/slice/LanguageLabel-slice.types';
import { RootState } from '../../store/rootStore';
import useSnackBar from '../../hooks/useSnackBar';
import { languageLabelApi } from '../../store/languageLabel/api/LanguageLabel.api';
import { ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import ConfirmDeleteDialog from '../../utils/confirm-delete-dialog/ConfirmDeleteDialog';
import AddDialog from './add-dialog/AddDialog';
import { RootStyle } from '../../pages/systemusers/SystemUser.styles';
import CommonToolbar from '../../utils/toolbar/CommonToolbar';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './LanguageLabel.constant';
import LanguageLabelTableRow from './languageLabel-row/LanguageLabelRow';
import TableNoData from '../table/TableNoData';
import TableLoadingData from '../table/TableLoadingData';
import { TablePaginationActions } from '../table/TablePaginationActions/TablePaginationActions';
import TableHeadCustom from '../table/table-head/CustomTableHead';
import useTable from '../../hooks/useTable';

function LanguageLabelModule() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [currentLanguageLabels, setLanguageLabels] = useState<LanguageLabel | undefined>(undefined);
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const [selectedId, setSelectedId] = useState<number | undefined>(undefined);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, LanguageLabelActionTypes>>();
  const { label, isLoading } = useSelector<RootState, ILanguageLabelState>((state: RootState) => state[languageLabelApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);

  const { alertSuccess, alertError } = useSnackBar();

  useEffect(() => {
    dispatch(
      languageLabelApi.endpoints.getLanguageLabels({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(1);
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setLanguageLabels(undefined);
    setIsEyeIconClicked(false);
    setIsEdit(false);
    setfilterKeyword('');

    dispatch(
      languageLabelApi.endpoints.getLanguageLabels({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  };

  const onOPenEditDialog = (id?: number) => {
    setIsEdit(true);
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.GET_LABELS}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.label;
        const { labelName, LabelTranslations } = responseBody;
        const englishTitle = LabelTranslations?.find((item: any) => item.languageId === '3')?.title;

        const userData: LanguageLabel = {
          id: id,
          title: englishTitle,
          language: 45,
          labelName: labelName,
          translations: LabelTranslations,
        };
        setLanguageLabels(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const onCloseConfirmDeleteDialog = () => {
    setSelectedId(undefined);
    setConfirmDeleteOpen(false);
    setIsEyeIconClicked(false);
    setfilterKeyword('');
  };

  const onDeleteCategory = async () => {
    try {
      if (selectedId) {
        await dispatch(languageLabelApi.endpoints.deleteLanguageLabel(selectedId));
        onCloseConfirmDeleteDialog();
        dispatch(alertSuccess(t('languageLabel.delete_success_message')));
        if (label?.next === null && label?.total <= page * rowsPerPage) {
          setPage(page - 1);
        }
        dispatch(
          languageLabelApi.endpoints.getLanguageLabels({
            page,
            limit: rowsPerPage,
            orderBy,
            order,
            keyword: debouncedSearchTerm,
          }),
        );
      }
    } catch (err) {
      dispatch(alertError(t('common.something_wrong_happend')));
    }
  };

  const onOpenAddDialog = () => {
    setLanguageLabels(undefined);
    setAddDialogOpen(true);
    setIsEdit(false);
  };

  const onConfirmDelete = (id?: number) => {
    setSelectedId(id);
    setConfirmDeleteOpen(true);
  };

  const isFullFilled = !isLoading && label && label?.label?.length > 0;

  return (
    <>
      <ConfirmDeleteDialog
        open={confirmDeleteOpen}
        onClose={onCloseConfirmDeleteDialog}
        onDelete={onDeleteCategory}
        confirm_delete_title={t('languageLabel.confirm_delete_title')}
        confirm_delete_message={t('languageLabel.confirm_delete_message')}
      />
      <AddDialog
        open={addDialogOpen}
        onClose={onCloseDialog}
        currentLanguageLabels={currentLanguageLabels}
        isUpdate={isEdit}
        isEyeIconClicked={isEyeIconClicked}
      />
      <RootStyle>
        <CommonToolbar
          filterKeyword={filterKeyword}
          onFilterKeyword={(value: string) => onFilterKeyword(value)}
          onOpenAddDialog={onOpenAddDialog}
          title={t('languageLabel.title')}
          message={t('languageLabel.add')}
        />
        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (label as ILanguageLabelResponse)?.label?.map((row: LanguageLabel) => {
                      return (
                        <LanguageLabelTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onOPenEditDialog={onOPenEditDialog}
                          onConfirmDelete={onConfirmDelete}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && label?.label?.length === 0} title={t('languageLabel.no_data')} />
                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={label ? label.total : 0}
                rowsPerPage={rowsPerPage}
                page={label && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}
export default LanguageLabelModule;
