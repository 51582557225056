import { combineReducers } from 'redux';
import { passwordDialogReducer } from './Dialog/reducer';
import { articleReducer } from './articles/reducer';
import { authReducer } from './authentication/reducer';
import { bugReducer } from './bugs/reducer';
import { compagniesReducer } from './campagnes/reducer';
import { counterReducer } from './counters/reducer';
import { countriesReducer } from './countries/reducer';
import { DashboardReducer } from './dashboard/reducer';
import { flagsReducer } from './flags/reducer';
import { passwordReducer } from './modifierMotDepasse/reducer';
import { opportunitiesReducer } from './opportunities/reducer';
import { paginationReducer } from './pagination/reducer';
import { snackBarReducer } from './snackBar/reducer';
import { universityApi } from './university/api/university.api';
import { systemUserApi } from './systemuser/api/systemuser.api';
import { userReducer } from './user/reducer';
import { usersReducer } from './users/reducer';
import { forgotPassReducer } from './forgotpassword/reducer';
import { permissionReducer } from './permission/reducer';
import { approvedAdsApi } from './approvedAds/api/ApprovedAds.api';
import { pendingAdsApi } from './pendingAds/api/PendingAds.api';
import { subscriptionPlanApi } from './subscriptionPlan/api/SubscriptionPlan.api';
import { revenuReportsApi } from './revenueReports/api/RevenueReports.api';
import { adsUserApi } from './adsUsers/api/AdsUsers.api';
import { howItWorksApi } from './howItWorks/api/HowItWorks.api';
import { categoriesApi } from './categories/api/Categories.api';
import { interestsApi } from './interests/api/Interests.api';
import { interestsCategoriesReducer } from './interestCategories/reducer';
import { languagesReducer } from './languages/reducer';
import { masterClassApi } from './masterClass/api/MasterClass.api';
import { tagsReducer } from './tags/reducer';
import { subscriptionPlansApi } from './subscriptionPlans/api/SubscriptionPlans.api';
import { advertisementsApi } from './advertisements/api/Advertisements.api';
import { masterClassAddsReducer } from './masterClassAds/reducer';
import { transactionReportApi } from './transactions/api/TransactionReports.api';
import { welcomeVideoApi } from './welcomeVideo/api/WelcomeVideo.api';
import { portfolioPlanApi } from './portfolioPlans/api/PortfolioPlans.api';
import { artistsApi } from './artists/api/ArtistsView.api';
import { portfolioTransactionReportApi } from './portfolioTransactions/api/PortfolioTransactions.api';
import { languageLabelApi } from './languageLabel/api/LanguageLabel.api';
import { artWeekApi } from './artsweek/api/ArtWeek.api';

export const rootReducer = combineReducers({
  auth: authReducer,
  pass: passwordReducer,
  passDialog: passwordDialogReducer,
  user: userReducer,
  bug: bugReducer,
  flags: flagsReducer,
  opportunities: opportunitiesReducer,
  articles: articleReducer,
  dashboard: DashboardReducer,
  countries: countriesReducer,
  users: usersReducer,
  pagination: paginationReducer,
  counter: counterReducer,
  compagnies: compagniesReducer,
  snackbar: snackBarReducer,
  forgotpass: forgotPassReducer,
  permissions: permissionReducer,
  categoriesList: interestsCategoriesReducer,
  languages: languagesReducer,
  tags: tagsReducer,
  masterClassAdds: masterClassAddsReducer,
  [universityApi.reducerPath]: universityApi.reducer,
  [systemUserApi.reducerPath]: systemUserApi.reducer,
  [approvedAdsApi.reducerPath]: approvedAdsApi.reducer,
  [pendingAdsApi.reducerPath]: pendingAdsApi.reducer,
  [subscriptionPlanApi.reducerPath]: subscriptionPlanApi.reducer,
  [revenuReportsApi.reducerPath]: revenuReportsApi.reducer,
  [adsUserApi.reducerPath]: adsUserApi.reducer,
  [howItWorksApi.reducerPath]: howItWorksApi.reducer,
  [categoriesApi.reducerPath]: categoriesApi.reducer,
  [interestsApi.reducerPath]: interestsApi.reducer,
  [masterClassApi.reducerPath]: masterClassApi.reducer,
  [subscriptionPlansApi.reducerPath]: subscriptionPlansApi.reducer,
  [advertisementsApi.reducerPath]: advertisementsApi.reducer,
  [transactionReportApi.reducerPath]: transactionReportApi.reducer,
  [welcomeVideoApi.reducerPath]: welcomeVideoApi.reducer,
  [portfolioPlanApi.reducerPath]: portfolioPlanApi.reducer,
  [artistsApi.reducerPath]: artistsApi.reducer,
  [portfolioTransactionReportApi.reducerPath]: portfolioTransactionReportApi.reducer,
  [languageLabelApi.reducerPath]: languageLabelApi.reducer,
  [artWeekApi.reducerPath]: artWeekApi.reducer,
});

export type RootState = ReturnType<typeof rootReducer>;
