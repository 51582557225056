/* eslint-disable @typescript-eslint/no-explicit-any */
import { AxiosResponse } from 'axios';
import { ThunkDispatch } from 'redux-thunk';
import { ENDPOINTS, sendAsyncRequest } from '../../../utils';
import { HttpMethod } from '../../../utils/httpMethods';
import { RootState } from '../../rootStore';
import { IPortfolioReportSuccessAction, PortfolioReportActionTypes } from '../slice/PortfolioTransactions-slice.types';
import { portfolioTransactionReportActions, portfolioTransactionReportReducer } from '../slice/PortfolioTransactions-slice';

export const portfolioTransactionReportApi = {
  reducerPath: 'portfolioTransactionUsers',
  reducer: portfolioTransactionReportReducer,
  endpoints: {
    getPortfolioTransactionReportsUsers: (params: any) => async (dispatch: ThunkDispatch<RootState, undefined, PortfolioReportActionTypes>) => {
      dispatch(portfolioTransactionReportActions.matchPending());
      sendAsyncRequest(HttpMethod.Get, ENDPOINTS.GET_PORTFOLIO_TRANSACTIONS, {}, true, { ...params })
        .then((response: AxiosResponse) => {
          const responseBody = response.data;
          dispatch(portfolioTransactionReportActions.matchFulFilled(responseBody, 'fetch') as IPortfolioReportSuccessAction);
        })
        .catch((error) => {
          console.log('error', error);
          dispatch(portfolioTransactionReportActions.matchRejected(error));
        });
    },
  },
};
