/* eslint-disable @typescript-eslint/no-explicit-any */
import { LanguageLabel } from '../../../models/LanguageLabel';
import {
  ActionTypes,
  IFetchLanguageLabelRejectedAction,
  IFetchLanguageLabelSuccessAction,
  ILanguageLabelPendingAction,
  ILanguageLabelResponse,
  ILanguageLabelState,
  LanguageLabelActionTypes,
} from './LanguageLabel-slice.types';

export const initialState: ILanguageLabelState = {
  isLoading: false,
  error: null,
  label: null,
  languageUsers: null,
};

export const languageLabelReducer = (state = initialState, action: LanguageLabelActionTypes): ILanguageLabelState => {
  switch (action.type) {
    case ActionTypes.PENDING:
      return {
        ...state,
        isLoading: true,
      };
    case ActionTypes.FETCH_LANGUAGE_LABEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        label: action.payload as ILanguageLabelResponse,
      };
    case ActionTypes.FETCH_LANGUAGE_LABEL_FAILURE:
      return {
        ...state,
        isLoading: false,
        error: action.payload,
      };
    case ActionTypes.CREATE_LANGUAGE_LABEL_SUCCESS:
      return {
        ...state,
        isLoading: false,
        languageUsers: action.payload as LanguageLabel,
      };
    default:
      return state;
  }
};

const matchPending = (): ILanguageLabelPendingAction => {
  return {
    type: ActionTypes.PENDING,
  };
};

const matchFulFilled = (
  data: ILanguageLabelResponse,
  action: 'fetch' | 'update' | 'create' | 'delete',
): IFetchLanguageLabelSuccessAction | LanguageLabel => {
  return {
    type: action === 'fetch' ? ActionTypes.FETCH_LANGUAGE_LABEL_SUCCESS : ActionTypes.CREATE_LANGUAGE_LABEL_SUCCESS,
    payload: data,
  };
};

const matchRejected = (error: any): IFetchLanguageLabelRejectedAction => {
  return {
    type: ActionTypes.FETCH_LANGUAGE_LABEL_FAILURE,
    payload: error,
  };
};

export const languageLabelActions = {
  matchPending,
  matchFulFilled,
  matchRejected,
};
