import React, { useEffect, useState, ChangeEvent } from 'react';
import { Box, Container, Table, TableBody, TableContainer, TablePagination, Stack } from '@mui/material';
import { useTranslation } from 'react-i18next';
import { TablePaginationActions } from '../table/TablePaginationActions/TablePaginationActions';
import TableLoadingData from '../table/TableLoadingData';
import TableNoData from '../table/TableNoData';
import { RootStyle } from '../../pages/systemusers/SystemUser.styles';
import TableHeadCustom from '../table/table-head/CustomTableHead';
import { useDebounce } from '@uidotdev/usehooks';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { RootState } from '../../store/rootStore';
import useTable from '../../hooks/useTable';
import MasterClass from '../../models/MasterClass';
import CommonToolbar from '../../utils/toolbar/CommonToolbar';
import { ENDPOINTS, sendAsyncRequest } from '../../utils';
import { HttpMethod } from '../../utils/httpMethods';
import { AxiosResponse } from 'axios';
import { clearNewTag } from '../../store/tags/actions';
import { tagsActionTypes } from '../../store/tags/models';
import { Welcome } from '../../models/Welcome';
import { IWelcomeVideoResponse, IWelcomeVideoState, WelcomeVideoActionTypes } from '../../store/welcomeVideo/slice/WelcomeVideo-slice.types';
import { welcomeVideoApi } from '../../store/welcomeVideo/api/WelcomeVideo.api';
import { TABLE_COLUMNS_CONFIG, initialPaginationState } from './WelcomeVideo.constant';
import WelcomeVideoTableRow from './welcomeVideo-row/WelcomeVideoTableRow';
import AddDialog from './add-dialog/AddDialog';
import { ContainerStyle, CustomTitle, GridStyle, InputStyle } from '../../utils/toolbar/CommonToolbar.styles';
import SearchIcon from '../svgComponents/searchIcon';

function WelcomeVideoListing() {
  const { t } = useTranslation();
  const [filterKeyword, setfilterKeyword] = useState('');
  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const [addDialogOpen, setAddDialogOpen] = useState(false);
  const debouncedSearchTerm = useDebounce(filterKeyword, 500);
  const [currentWelcomeVideo, setCurrentWelcomeVideo] = useState<Welcome | undefined>(undefined);
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, WelcomeVideoActionTypes>>();
  const dispatchTags = useDispatch<ThunkDispatch<RootState, undefined, tagsActionTypes>>();
  const { data, isLoading } = useSelector<RootState, IWelcomeVideoState>((state: RootState) => state[welcomeVideoApi.reducerPath]);
  const { setPage, onSort, order, orderBy, page, rowsPerPage, onChangePage, onChangeRowsPerPage } = useTable(initialPaginationState);
  const [isEdit, setIsEdit] = useState(false);
  useEffect(() => {
    dispatch(
      welcomeVideoApi.endpoints.getWelcomeVideoList({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  }, [order, orderBy, page, rowsPerPage, debouncedSearchTerm, dispatch]);

  const onFilterKeyword = (keyword: string) => {
    setfilterKeyword(keyword);
    setPage(1);
  };

  const onShowDetails = async (id?: number, isShow?: boolean) => {
    setIsEdit(false);
    try {
      const response = await sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.WELCOME_VIDEO}/${id}`, {}, true, {});
      const responseBody = response.data.welcomeVideo;
      const { title, video, status } = responseBody;
      const userData: Welcome = {
        id: id,
        title: title,
        video: video,
        status: status,
      };
      setIsEyeIconClicked(isShow ?? false);
      setCurrentWelcomeVideo(userData);
      setAddDialogOpen(true);
    } catch (err) {
      console.log('error', err);
    }
  };

  const onCloseDialog = () => {
    setAddDialogOpen(false);
    setCurrentWelcomeVideo(undefined);
    setIsEyeIconClicked(false);
    setIsEdit(false);
    setfilterKeyword('');
    dispatchTags(clearNewTag());
    dispatch(
      welcomeVideoApi.endpoints.getWelcomeVideoList({
        page,
        limit: rowsPerPage,
        orderBy,
        order,
        keyword: debouncedSearchTerm,
      }),
    );
  };

  const onOpenAddDialog = () => {
    setCurrentWelcomeVideo(undefined);
    setAddDialogOpen(true);
    setIsEdit(false);
  };

  const onOPenEditDialog = (id?: number) => {
    setIsEdit(true);
    sendAsyncRequest(HttpMethod.Get, `${ENDPOINTS.WELCOME_VIDEO}/${id}`, {}, true, {})
      .then((response: AxiosResponse) => {
        const responseBody = response.data.welcomeVideo;
        const { title, video, status } = responseBody;

        const userData: Welcome = {
          id: id,
          title: title,
          video: video,
          status: status,
        };
        setCurrentWelcomeVideo(userData);
      })
      .catch((err) => {
        console.log('error', err);
      });
    setAddDialogOpen(true);
  };

  const isFullFilled = !isLoading && data && data?.welComeVideo?.length > 0;

  return (
    <>
      <AddDialog
        open={addDialogOpen}
        onClose={onCloseDialog}
        currentWelcomeVideo={currentWelcomeVideo}
        isUpdate={isEdit}
        isEyeIconClicked={isEyeIconClicked}
      />
      <RootStyle>
        {/* <CommonToolbar
          filterKeyword={filterKeyword}
          onFilterKeyword={(value: string) => onFilterKeyword(value)}
          onOpenAddDialog={onOpenAddDialog}
          title={t('masterClass.title')}
          message={t('masterClass.add')}
        /> */}
        <ContainerStyle>
          <GridStyle>
            <CustomTitle>{t('masterClass.welcome_title')}</CustomTitle>
            <Stack direction="column" spacing={4}>
              <InputStyle
                value={filterKeyword}
                placeholder={t('common.search')}
                onChange={(e: ChangeEvent<HTMLInputElement>) => onFilterKeyword(e.target.value)}
                InputProps={{
                  startAdornment: <SearchIcon />,
                }}
              />
            </Stack>
          </GridStyle>
        </ContainerStyle>
        <Container sx={{ minHeight: '70vh' }}>
          <div className="table-block interest-panel">
            <TableContainer sx={{ minWidth: 800, position: 'relative' }}>
              <Table size={'medium'}>
                <TableHeadCustom order={order} orderBy={orderBy} columnsConfig={TABLE_COLUMNS_CONFIG} onSort={onSort} />
                <TableBody>
                  {isFullFilled &&
                    (data as IWelcomeVideoResponse)?.welComeVideo?.map((row: Welcome) => {
                      return (
                        <WelcomeVideoTableRow
                          key={row.id}
                          row={row}
                          selected={false}
                          columnsConfig={TABLE_COLUMNS_CONFIG}
                          onShowDetails={(id) => onShowDetails(id, true)}
                          onOPenEditDialog={onOPenEditDialog}
                        />
                      );
                    })}
                  <TableNoData isNotFound={!isLoading && data?.welComeVideo?.length === 0} title={t('masterClass.welcome_no_data')} />

                  <TableLoadingData isLoading={isLoading} />
                </TableBody>
              </Table>
            </TableContainer>
          </div>
          {isFullFilled && (
            <Box sx={{ position: 'relative' }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, 50]}
                component="div"
                count={data ? data.total : 0}
                rowsPerPage={rowsPerPage}
                page={data && page ? page - 1 : 0}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
                ActionsComponent={TablePaginationActions}
                labelRowsPerPage={t('common.rowsperpage')}
                sx={{
                  '& .MuiInputBase-root': {
                    maxWidth: '60px',
                  },
                }}
              />
            </Box>
          )}
        </Container>
      </RootStyle>
    </>
  );
}

export default WelcomeVideoListing;
