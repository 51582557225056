import { LanguageLabel } from '../../../models/LanguageLabel';

export interface ILanguageLabelResponse {
  total: number;
  next: number | null;
  previous: number | null;
  label: LanguageLabel[];
}

export interface ILanguageLabelState {
  isLoading: boolean;
  label: ILanguageLabelResponse | null;
  languageUsers: LanguageLabel | null;
  error: string | null;
}

export enum ActionTypes {
  PENDING = 'PENDING',
  FETCH_LANGUAGE_LABEL_SUCCESS = 'FETCH_LANGUAGE_LABEL_SUCCESS',
  FETCH_LANGUAGE_LABEL_FAILURE = 'FETCH_LANGUAGE_LABEL_FAILURE',
  CREATE_LANGUAGE_LABEL_SUCCESS = 'CREATE_LANGUAGE_LABEL_SUCCESS',
}

export interface ILanguageLabelPendingAction {
  type: typeof ActionTypes.PENDING;
}

export interface IFetchLanguageLabelSuccessAction {
  type: typeof ActionTypes.FETCH_LANGUAGE_LABEL_SUCCESS | typeof ActionTypes.CREATE_LANGUAGE_LABEL_SUCCESS;
  payload: ILanguageLabelResponse | LanguageLabel;
}

export interface IFetchLanguageLabelRejectedAction {
  type: typeof ActionTypes.FETCH_LANGUAGE_LABEL_FAILURE;
  payload: string;
}

export type LanguageLabelActionTypes = ILanguageLabelPendingAction | IFetchLanguageLabelSuccessAction | IFetchLanguageLabelRejectedAction;
