/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable react/react-in-jsx-scope */
import PlayCircleOutlineIcon from '@mui/icons-material/PlayCircleOutline';
import { Box, Button, FormGroup, Grid, Typography } from '@mui/material';
import { useEffect, useMemo, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { WelcomeVideoFormProps } from './WelcomeVideo-form.types';
import { theme } from '../../../theme/theme';
import AdminVideoControl from '../../adds/menubar/approved/form/VideoControl';
import { Welcome } from '../../../models/Welcome';

export default function ViewWelcoemVideo({ currentWelcomeVideo, isUpdate, isEyeIconClicked }: WelcomeVideoFormProps) {
  const customStyle = {
    marginBottom: '0.5rem',
    fontFamily: 'SFPro-Regular',
    color: theme.palette.secondary.dark,
  };

  const { t } = useTranslation();
  const [postURL, setPostURL] = useState<string | undefined>(undefined);
  const [postType, setPostType] = useState<string | undefined>(undefined);
  const [videoURL, setVideoURL] = useState<string | undefined>(undefined);
  const [videoType, setVideoType] = useState<string | undefined>(undefined);
  const [isVideoPopupOpen, setVideoPopupOpen] = useState(false);

  const defaultValues = useMemo<Welcome>(
    () => ({
      title: currentWelcomeVideo?.title || '',
      video: currentWelcomeVideo?.video || '',
      status: currentWelcomeVideo?.status !== undefined ? currentWelcomeVideo?.status : 1, // Ensure the correct value is set
    }),
    [currentWelcomeVideo],
  );
  const methods = useForm<Welcome>({
    defaultValues,
    mode: 'all',
  });

  const { watch, reset } = methods;

  const values = watch();

  useEffect(() => {
    if (!isUpdate) {
      reset(defaultValues);
    }
  }, [isUpdate, currentWelcomeVideo]);

  useEffect(() => {
    if (currentWelcomeVideo) {
      const posturl = currentWelcomeVideo?.video || '';
      const postExtension = posturl.split('.').pop()?.toLowerCase() || '';
      setPostURL(posturl);
      setPostType(postExtension);
    }
  }, [currentWelcomeVideo]);

  useEffect(() => {
    const updatedDefaultValues = {
      title: currentWelcomeVideo?.title || '',
      video: currentWelcomeVideo?.video || '',
      status: currentWelcomeVideo?.status !== undefined ? currentWelcomeVideo?.status : 1, // Ensure the correct value is set
    };
    methods.reset(updatedDefaultValues);
  }, [currentWelcomeVideo, isUpdate]);

  const openVideoPopup = (type: string) => {
    if (type === 'post') {
      setVideoURL(postURL);
      setVideoType(postType);
    }
    setVideoPopupOpen(true);
  };

  const closeVideoPopup = () => {
    setVideoPopupOpen(false);
  };

  return (
    <>
      <Grid className="artgapi-modal" container spacing={3}>
        <Grid item xs={12} md={12}>
          <Box sx={{ p: 3 }}>
            <Box sx={{ mb: 3 }}>
              <Box
                sx={{
                  display: 'grid',
                  columnGap: 3,
                  rowGap: 2,
                  gridTemplateColumns: { xs: 'repeat(1, 1fr)', sm: 'repeat(3, 1fr)' },
                }}
              >
                <div className="col">
                  <Typography>{t('masterClass.form_fields.title.placeholder')}</Typography>
                  <label style={customStyle}>{values?.title}</label>
                </div>
                <div className="col">
                  <Typography>{t('masterClass.form_fields.show_video.placeholder')}</Typography>
                  <label style={customStyle}>{values?.status === 1 ? 'Yes' : 'No'}</label>
                </div>
                <div className="col">
                  <Typography>{t('masterClass.form_fields.video.placeholder')}</Typography>

                  {values?.video && (
                    <Grid>
                      <div>
                        <div style={{ display: 'flex', marginBottom: '0', alignItems: 'center' }}>
                          {postURL && (
                            <div
                              style={{
                                width: '100px',
                                height: '100px',
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'center',
                                position: 'relative',
                              }}
                            >
                              <video
                                style={{ cursor: 'pointer', width: '100%', objectFit: 'cover', height: '100%' }}
                                src={postURL}
                                onClick={() => openVideoPopup('post')}
                              ></video>
                              <span
                                style={{
                                  backgroundColor: 'white',
                                  display: 'flex',
                                  alignItems: 'center',
                                  borderRadius: '9999px',
                                  position: 'absolute',
                                  width: '1.5rem',
                                  height: '1.5rem',
                                  cursor: 'pointer',
                                  justifyContent: 'center',
                                }}
                              >
                                <PlayCircleOutlineIcon className="text-base" onClick={() => openVideoPopup('post')} />
                              </span>
                            </div>
                          )}
                        </div>
                      </div>
                    </Grid>
                  )}
                </div>
              </Box>
            </Box>
          </Box>
        </Grid>
      </Grid>
      {isVideoPopupOpen && (
        <AdminVideoControl
          isVideoPopupOpen={isVideoPopupOpen}
          closeVideoPopup={closeVideoPopup}
          videourl={videoURL || ''}
          selectedVideo={videoType || ''}
        />
      )}
    </>
  );
}
