import React from 'react';
import Articles from '../pages/articles/Articles';
import BugsPage from '../pages/bugs/Bugs';
import OpportunitiesPage from '../pages/opportunities/Opportunities';
import Profiles from '../pages/profile/Profiles';
import Settings from '../pages/settings/Settings';
import SystemUsers from '../pages/systemusers/SystemUser';
import UniversityList from '../pages/university/UniversityList';
import { UsersMenu } from '../components/menuBar/usersMenu';
import { SignamlmentMenu } from '../components/signalement/SignamlmentMenu';
import { CompaniesMenu } from '../components/menuBar/companiesMenu';
import { AddsMenu } from '../components/adds/menubar/addsMenu';
import { Dashboard } from '../pages';
import Interests from '../components/interests/interests/Interests';
import Categories from '../components/interests/categories/Categories';
import MasterClassListing from '../components/masterClass/MasterClassListing';
import SubscriptionPlanListing from '../components/subscriptionPlan/SubscriptionPlanListing';
import AdvertisementListing from '../components/advertisements/AdvertisementsListing';
import WelcomeVideoListing from '../components/welcomeVideo/WelcomeVideoListing';

export default function getRouteElement(subtitle: string): JSX.Element {
  switch (subtitle) {
    case 'Dashboard':
      return <Dashboard />;
    case 'Admins':
      return <UsersMenu indexVal={0} role="admin" />;
    case 'Artusers':
      return <UsersMenu indexVal={1} role="artist" />;
    case 'Arts':
      return <UsersMenu indexVal={2} role="" />;
    case 'Artsworks':
      return <UsersMenu indexVal={3} role="" />;
    case 'Adsusers':
      return <UsersMenu indexVal={4} role="" />;
    case 'Signalments':
      return <SignamlmentMenu indexVal={0} />;
    case 'RevenueReports':
      return <SignamlmentMenu indexVal={1} />;
    case 'AdsReports':
      return <SignamlmentMenu indexVal={2} />;
    case 'Interstitiles':
      return <CompaniesMenu indexVal={0} />;
    case 'Post Sponsers':
      return <CompaniesMenu indexVal={1} />;
    case 'Pending ads':
      return <AddsMenu indexVal={0} />;
    case 'Approved ads':
      return <AddsMenu indexVal={1} />;
    case 'Subscription plan':
      return <AddsMenu indexVal={2} />;
    case 'How it works':
      return <AddsMenu indexVal={3} />;
    case 'Opportunities':
      return <OpportunitiesPage />;
    case 'Articles':
      return <Articles />;
    case 'Bugs':
      return <BugsPage />;
    case 'Universites':
      return <UniversityList />;
    case 'Subadmin':
      return <SystemUsers />;
    case 'Settings':
      return <Settings />;
    case 'Profiles':
      return <Profiles />;
    case 'InterestsData':
      return <Interests />;
    case 'Categories':
      return <Categories />;
    case 'MasterClassData':
      return <MasterClassListing />;
    case 'SubscriptionPlan':
      return <SubscriptionPlanListing />;
    case 'Advertisements':
      return <AdvertisementListing />;
    case 'WelcomeVideo':
      return <WelcomeVideoListing />;
    default:
      return <div>Unknown Route</div>;
  }
}
