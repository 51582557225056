import { AppBar, Grid } from '@mui/material';
import Box from '@mui/material/Box';
import { ThemeProvider, useTheme } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ThunkDispatch } from 'redux-thunk';
import { getCriteria, getPageNumber } from '../../store/pagination/actions';
import { PaginationActionTypes } from '../../store/pagination/models';
import { RootState } from '../../store/rootStore';
import { getUsers } from '../../store/users/actions';
import { usersActionTypes } from '../../store/users/models';
import { menuBar } from '../../theme/menuBarTheme';
import { OnTheTable } from '../table/onTheTable/OnTheTable';
import { TableUsers } from '../table/tableFactory/TableUsers';
import { adminHeader, artLoversHeader, artistsHeader, galerieHeader } from '../user/usersHeader';
import AdsUsers from '../adsUsers/AdsUsers';
import ArtUsersDetails from '../artsOfTheWeek/ArtUsers';
import ArtWorkUsersDetails from '../artistsOfTheWeek/ArtWorkUsers';
import DateFilter from '../../utils/DateFilter';
import ViewArtistListing from '../artists/viewArtists/ViewArtistsListing';

interface TabPanelProps {
  children?: React.ReactNode;
  dir?: string;
  index: number;
  value: number;
}

function TabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <Grid
      sx={{ width: 1 }}
      className="artgapi-resposive"
      role="tabpanel"
      hidden={value !== index}
      id={`full-width-tabpanel-${index}`}
      aria-labelledby={`full-width-tab-${index}`}
      {...other}
    >
      {value === index && <Box p={9}>{children}</Box>}
    </Grid>
  );
}

export const UsersMenu = ({ indexVal, role }: { indexVal: number; role: string }) => {
  const usersState = useSelector((state: RootState) => state.users);
  const { loader, users } = usersState;
  const dispatch = useDispatch<ThunkDispatch<RootState, undefined, usersActionTypes | PaginationActionTypes>>();
  const theme = useTheme();
  const [value, setValue] = React.useState(0);
  const paginationState = useSelector((state: RootState) => state.pagination);
  const { pageNumber, perPage, criteria, total } = paginationState;
  const [startDate, setStartDate] = useState<Date | null>(null);
  const [endDate, setEndDate] = useState<Date | null>(null);
  const [selectedName, setSelectedName] = useState<string | undefined>(undefined);

  const [isEyeIconClicked, setIsEyeIconClicked] = useState(false);
  const [selectedId, setSelectedId] = useState<number>(0);

  function formatDate(date: Date) {
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, '0');
    const day = String(date.getDate()).padStart(2, '0');
    return `${year}-${month}-${day}`;
  }

  const formattedStartDate = startDate ? formatDate(startDate) : '';
  const formattedEndDate = endDate ? formatDate(endDate) : '';

  // const handleChange = (event: React.SyntheticEvent, newValue: number) => {
  //   setValue(newValue);
  // };
  // const handleNavigation = (role: string) => {
  //   setRoleName(role);
  //   dispatch(getPageNumber(0));
  //   dispatch(getCriteria(''));
  // };
  useEffect(() => {
    dispatch(getPageNumber(0));
    dispatch(getCriteria('')); // Reset criteria when indexVal changes
    setIsEyeIconClicked(false);
  }, [indexVal]);

  useEffect(() => {
    let mounted = true;
    if (mounted) {
      if (indexVal === 0) {
        dispatch(getUsers({ roleName: role, page: pageNumber + 1, limit: perPage, criteria: criteria }));
      } else if (indexVal === 1) {
        dispatch(
          getUsers({
            roleName: role,
            page: pageNumber + 1,
            limit: perPage,
            criteria: criteria,
            startDate: formattedStartDate,
            endDate: formattedEndDate,
          }),
        );
      }
    }
    return () => {
      mounted = false;
    };
  }, [role, perPage, pageNumber, criteria, total, indexVal, startDate, endDate, isEyeIconClicked]);

  const handleClear = () => {
    setStartDate(null);
    setEndDate(null);
  };

  const handleStartDate = (date: Date | null) => {
    setStartDate(date);
  };

  const handleEndDate = (date: Date | null) => {
    setEndDate(date);
  };

  const onShowDetails = (id?: number, name?: string, isShow?: boolean) => {
    setIsEyeIconClicked(isShow ?? false);
    setSelectedId(id || 0);
    setSelectedName(name || '');
  };

  const onCloseDetails = (isShow?: boolean) => {
    setIsEyeIconClicked(isShow ?? false);
  };

  return (
    <>
      <ThemeProvider theme={menuBar}>
        <AppBar sx={{ position: 'sticky', top: '70px', zIndex: 5 }}>
          <Box></Box>
        </AppBar>
      </ThemeProvider>
      {/* <Grid
        container
        sx={{
          display: 'flex',
          flexDirection: 'column',
          backgroundColor: theme.palette.secondary.main,
        }}
      > */}
      {indexVal === 0 ? (
        <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
          <OnTheTable title="Admins" ShowSearchField={true} criteria={criteria} />
          <TableUsers headItems={adminHeader} bodyData={users} loading={loader} isView={false} />
        </TabPanel>
      ) : indexVal === 1 ? (
        <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
          {isEyeIconClicked ? (
            <ViewArtistListing selectedId={selectedId} onCloseDetails={onCloseDetails} selectedName={selectedName || ''} />
          ) : (
            <>
              <OnTheTable title="Artistes" ShowSearchField={true} criteria={criteria} />
              <DateFilter
                startDate={startDate}
                endDate={endDate}
                handleClear={handleClear}
                handleStartDate={handleStartDate}
                handleEndDate={handleEndDate}
              />
              <TableUsers
                headItems={artistsHeader}
                bodyData={users}
                loading={loader}
                isView={true}
                onShowDetails={(id, name) => onShowDetails(id, name, true)}
              />
            </>
          )}
        </TabPanel>
      ) : indexVal === 2 ? (
        <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
          <ArtUsersDetails />
          {/* <OnTheTable title="Galeries" ShowSearchField={true} />
            <TableUsers headItems={galerieHeader} bodyData={users} loading={loader} /> */}
        </TabPanel>
      ) : indexVal === 3 ? (
        <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
          <ArtWorkUsersDetails />
          {/* <OnTheTable title="Art lovers" ShowSearchField={true} />
            <TableUsers headItems={artLoversHeader} bodyData={users} loading={loader} /> */}
        </TabPanel>
      ) : (
        <TabPanel value={indexVal} index={indexVal} dir={theme.direction}>
          <AdsUsers />
        </TabPanel>
      )}
      {/* </Grid> */}
    </>
  );
};
