export const languageLabel = {
  no_data: 'Aucune étiquettes trouvée',
  title: 'Étiquettes de langue',
  add: 'CRÉER UNE ÉTIQUETTE',
  confirm_delete_title: 'Confirmer la suppression',
  confirm_delete_message: 'Êtes-vous sûr de vouloir supprimer ce libellé ?',
  delete_success_message: `L'étiquette a été supprimée avec succès`,
  view_language_title: `Afficher les détails de l'étiquette`,
  update_language_title: 'Modifier une étiquette',
  add_language_title: 'Créer une étiquette',
  create_success_message: `L'étiquette a été créée avec succès`,
  update_success_message: `L'étiquette a été modifiée avec succès`,
  form_fields: {
    title: {
      label: 'Titre de langue*',
      placeholder: 'Titre de langue',
      required_error_message: 'Le champ du titre de la langue est requis',
      max_length_error_message: 'Le titre de la langue ne peut pas comporter plus de 60 caractères',
    },
    labelName: {
      label: `Nom de l'étiquette*`,
      placeholder: `Nom de l'étiquette`,
      required_error_message: `Le champ du nom de l'étiquette est requis`,
    },
    language: {
      label: 'Langue*',
      placeholder: 'Langue',
      required_error_message: 'Toutes les langues sont requises',
    },
  },
};
